/**
 * Sets up the service worker for handling Web Push and registers the push subscription.
 * @param {string} serviceWorkerURL - URL to the service worker for notifications.
 * @returns {Promise<any>} registration
 */
export async function subscribeWebPush(serviceWorkerURL) {
  const registration = await navigator.serviceWorker.register(serviceWorkerURL);

  const response = await fetch("/apps/magmo/server_push_token");
  const result = await response.json();
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: result.key,
  });

  await fetch("/apps/magmo/subscribe_webpush", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ subscription: subscription }),
  });

  return registration;
}

/**
 * Wrapper for the Notification.requestPermission API, ensuring that it's always a Promise (not a callback)
 * @returns {Promise<'granted' | 'default' | 'denied'>} Result of Notification.requestPermission
 */
export function askNotificationPermission() {
  return new Promise(function (resolve, reject) {
    const maybePromise = Notification.requestPermission(resolve);

    if (maybePromise) {
      maybePromise.then(resolve, reject);
    }
  });
}

/**
 * Injects the notification center navigation icon into the specified navigation container. Should only be called ones on page initialization.
 * @param {Node} headerIconsContainer DOM Node of the navigation container to inject the icon to.
 * @param {string} serviceWorkerURL URL of the service worker.
 */
export function injectNotificationCenter(
  headerIconsContainer,
  serviceWorkerURL,
) {
  const notificationCenterTemplate = document.getElementById(
    "notification-center-template",
  );
  const notificationCenter = notificationCenterTemplate.content.cloneNode(true);
  headerIconsContainer.appendChild(notificationCenter);

  const badge = document.getElementsByClassName(
    "notification-center__badge",
  )[0];
  const icon = document.getElementsByClassName("notification-center__icon")[0];
  const drawer = document.getElementsByClassName(
    "notification-center__drawer",
  )[0];
  const list = document.getElementsByClassName("notification-center__list")[0];
  const emptystate = document.getElementsByClassName(
    "notification-center__emptystate",
  )[0];
  const notifyToggle = document.getElementsByClassName(
    "notification-center__notify",
  )[0];

  const listItem = document.getElementById(
    "notification-center__list-template",
  );

  fetch("/apps/magmo/notifications", {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Could not get notifications");
      }
      return response.json();
    })
    .then(({ notifications }) => {
      if (notifications.length <= 0) {
        list.classList.add("hidden");
        emptystate.classList.remove("hidden");
        return;
      }
      emptystate.classList.add("hidden");
      list.innerHTML = "";
      list.classList.remove("hidden");

      const aggregatedNotifs = aggregateNotifications(notifications).reverse();

      let displayBadge = false;
      for (const aggregatedNotification of aggregatedNotifs) {
        const consolidatedNotification = notificiationConsolidator(
          aggregatedNotification,
        );

        if (consolidatedNotification.unreadNotificationIDs.length > 0) {
          displayBadge = true;
        }

        const node = createListItem(consolidatedNotification);
        list.appendChild(node);
      }

      if (displayBadge) {
        badge.classList.remove("hidden");
      }
    });

  const toggleDrawer = (event) => {
    event.stopPropagation();
    if (drawer.classList.contains("hidden")) {
      const children = list.querySelectorAll(".notification-center__list-item");
      for (const child of children) {
        const thisElement = document.getElementById(child.id);

        if (child.dataset.read === "true") {
          thisElement.classList.remove(
            "notification-center__list-item--unread",
          );
          continue;
        }

        const unreadIDs = thisElement.dataset.notifs.split(",");

        // mark unread notifications as read

        unreadIDs.forEach((id) => {
          fetch("/apps/magmo/notifications/" + id, {
            method: "POST",
          });
        });

        thisElement.dataset.read = true;
      }

      badge.classList.add("hidden");
      badge.textContent = "";

      // show the drawer
      drawer.classList.remove("hidden");
    } else {
      drawer.classList.add("hidden");
    }
  };

  notificationCenter.addEventListener("click", toggleDrawer);
  icon.addEventListener("click", toggleDrawer);

  const createListItem = (notification) => {
    const node = listItem.content.cloneNode(true);
    // Cannot add ID or classes to node directly
    // We need to do a query selector first and then add it to that.
    const liElement = node.querySelector("li");
    liElement.id = notification.id;
    let notificationData = notification.data;
    if (typeof notification.data === "string") {
      notificationData = JSON.parse(notification.data);
    }
    liElement.dataset.read = true;
    liElement.dataset.notifs = notification.unreadNotificationIDs;

    if (notification.unreadNotificationIDs.length > 0) {
      liElement.classList.add("notification-center__list-item--unread");
      liElement.dataset.read = false;
    }

    node.querySelector("img").src = notificationData?.image;

    node.querySelector(".notification-center__list-text").textContent =
      notification.copy;

    // clicking on the item should jump to wishlist
    node.querySelector("li").addEventListener("click", function (event) {
      event.preventDefault();
      window.location = notification.data.targetURL || "/pages/wishlist";
    });
    return node;
  };

  // Commented out for now due to requirement change

  if (!("serviceWorker" in navigator)) {
    notifyToggle.classList.add("hidden");
  } else {
    switch (Notification.permission) {
      case "default":
        notifyToggle.addEventListener("click", function (event) {
          event.stopPropagation();
          askNotificationPermission(serviceWorkerURL).then((result) => {
            if (result === "granted") {
              notifyToggle.classList.add("hidden");
              subscribeWebPush(serviceWorkerURL);
            }
          });
        });
        break;
      case "granted":
        notifyToggle.classList.add("hidden");
        break;
      case "denied":
        notifyToggle.textContent =
          "Notification disabled. See browser settings to enable notifications.";
        break;
    }
  }
}

/**
 *
 * @param {Notification[]} notifications array of notification objects
 * @param {number} [groupingThresholdMS] threshold from the previous timestamp from which to break the notification
 * @returns {Notification[][]} Aggregated notifications, each element is an array of notification grouped by the aggregation criteria
 */
function aggregateNotifications(
  notifications,
  groupingThresholdMS = 5 * 60 * 1000,
) {
  // ascending order
  const sortedNotifs = notifications.sort(
    (a, b) => Date.parse(a.sent_at) - Date.parse(b.sent_at),
  );
  const aggregate = [];
  for (let i = 0; i < sortedNotifs.length; i++) {
    const n = sortedNotifs[i];
    if (i == 0) {
      aggregate.push([n]);
    } else {
      const prevNotif = sortedNotifs[i - 1];
      if (
        n.type === prevNotif.type &&
        Date.parse(n.sent_at) - Date.parse(prevNotif.sent_at) <
          groupingThresholdMS
      ) {
        aggregate[aggregate.length - 1].push(n);
      } else {
        aggregate.push([n]);
      }
    }
  }
  return aggregate;
}

/**
 * Consolidates notifications and extracts relevant information for display.
 * @param {Array} aggregatedNotification - An array of notifications that went through the aggregateNotifications function
 * @returns {object} An object containing the consolidated notification information with copy and unreadNotificationIDs.
 */
function notificiationConsolidator(aggregatedNotification) {
  const unreadNotifs = aggregatedNotification
    .filter(
      (notification) =>
        !notification.read_at ||
        notification.read_at === "1970-01-01T00:00:00.000Z",
    )
    .map((notification) => notification.id);

  const sameVariant = isSameVariant(aggregatedNotification);
  // we want the most recent notification because of the image url.
  const currentNotification =
    aggregatedNotification[aggregatedNotification.length - 1];
  let copy = "";

  let notificationData = currentNotification.data;
  if (typeof currentNotification.data === "string") {
    notificationData = JSON.parse(currentNotification.data);
  }

  const variantName = notificationData.name;

  switch (currentNotification.type) {
    case "BACK_IN_STOCK":
      copy = sameVariant
        ? `Your wishlisted item ${variantName} is back in stock`
        : "You have items in your wishlist that are back in stock.";
      break;
    case "NEW_ITEM":
      copy = sameVariant
        ? `New product ${variantName} is available`
        : "New products are available!";
      break;
    // Add more cases for other alert types if needed
    default:
      copy = "Unknown alert type.";
  }

  return {
    ...currentNotification,
    unreadNotificationIDs: unreadNotifs,
    copy: copy,
  };
}

/**
 * Checks if all notifications in the array belong to the same product variant
 * based on the URI field in the notification data.
 * Since the notifications object doesn't send us the variant ID explicity,
 * we can make the assumption that it is the same variant if it goes to the same URL.
 * The URL also contains the variant ID.
 * @param {Array} notifications - An array of notification objects.
 * @returns {boolean} True if all notifications belong to the same variant and false if not.
 */
function isSameVariant(notifications) {
  const firstVariantURI = notifications[0].data?.uri;
  for (let i = 1; i < notifications.length; i++) {
    if (notifications[i].data?.uri !== firstVariantURI) {
      return false;
    }
  }
  return true;
}
